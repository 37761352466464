
export enum ASIN_STATUS {
    BADGED,
    UNBADGED
}

export const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 megabyte

export const ASIN_UPLOAD_CHUNK_SIZE = 100;

export interface AddAsinsForBadgingApiCallResult {
    invalidAsins: string[];
    unprocessedAsins: string[];
}
  
export interface ASINChunkProcessingResult {
    success: boolean;
    data?: AddAsinsForBadgingApiCallResult;
    error?: string;
}

export interface BadgingRequestBody {
    asins: string[];
}

export interface BadgingStatusResult {
    true: string[];
    false: string[];
}

export const API_CALL_SUCCESS_MESSAGE = "Processing Completed"

export const MAX_NUMBER_OF_ASINS_ALLOWED = 100;

export const CONCURRENCY_LIMIT_FOR_ASIN_STORAGE_API_CALL = 3;

export const CSV_FILE_SIZE_LIMIT_EXCEED_ERROR = "File size exceeds 2MB limit. Please choose a smaller file."

export const CSV_FILE_PARSE_ERROR = "There was error parsing the uploaded file, please check the validity of the csv file and try again";