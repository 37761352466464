import React, { useState, ChangeEvent, FormEvent } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    Container,
    Paper,
    Grid,
    List,
    ListItem,
    ListItemText,
    Snackbar
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ASIN_STATUS } from "src/constants/OOCFBADiscoverabilityConstants";
import { isLegalAsin } from "src/utils/StringUtil";
import { BadgingStatusResult } from "src/constants/OOCFBADiscoverabilityConstants";
import { useSelector } from "react-redux";
import { CombineReducerState } from "src/combineReducers";
import { routes } from "src/data/Routes";
import apiRequest from "src/utils/ApiRequest";
import LinearProgress from '@mui/material/LinearProgress';
import { MAX_NUMBER_OF_ASINS_ALLOWED } from "src/constants/OOCFBADiscoverabilityConstants";

const CheckBadgingStatus: React.FC = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [inputAsins, setInputAsins] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [badgingStatusResults, setBadgingStatusResults] = useState<BadgingStatusResult | null>(null);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const apiRequestPath = routes.isp.OOCFBADiscoverability.checkBadgingStatus;

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const values = value.split(",").map(v => v.trim()).filter(v => v !== "");

        if (values.length <= MAX_NUMBER_OF_ASINS_ALLOWED) {
            setInputAsins(value);
            const invalidASINs = values.filter(v => !isLegalAsin(v));
            setError(invalidASINs.length > 0 ? `Invalid ASINs: ${invalidASINs.join(", ")}` : null);
        } else {
            const limitedValues = values.slice(0, 100);
            setInputAsins(limitedValues.join(", "));
            setError(`Maximum of ${MAX_NUMBER_OF_ASINS_ALLOWED} ASINs reached.`);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const encodedAsins = btoa((inputAsins));
            const response = await apiRequest(baseUrl + apiRequestPath, {
                encodedAsins: encodedAsins
            });
            if(!response.ok){
                throw new Error(`Server responded with status: ${response.status}`)
            }
            const data = await response.json() as BadgingStatusResult;
            setBadgingStatusResults(data)
        } catch (error) {
            console.error("Error checking Badging Status:", error);
            setError(
                error instanceof Error 
                    ? `Error: ${error.message}`
                    : 'An unexpected error occurred while checking Badging Status.'
            );
        }
        setIsLoading(false);
    };

    const handleCopy = (type: ASIN_STATUS) => {
        if (!badgingStatusResults) return;
        
        const asins = type === ASIN_STATUS.BADGED 
            ? badgingStatusResults.true 
            : badgingStatusResults.false;
            
        navigator.clipboard.writeText(asins.join(', '));
        setSnackbarMessage(`${type === ASIN_STATUS.BADGED ? 'Badged' : 'Unbadged'} ASINs copied to clipboard!`);
        setSnackbarOpen(true);
    };


    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Check Badged ASINs
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Enter comma-separated ASINs (up to 100)"
                        value={inputAsins}
                        onChange={handleInputChange}
                        error={Boolean(error)}
                        helperText={error || `${inputAsins.split(",").filter(v => v.trim() !== "").length}/100 ASINs entered`}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={inputAsins.trim() === "" || Boolean(error) || isLoading}
                        sx={{ mt: 2 }}
                    >
                        Submit
                    </Button>
                </Box>
                {isLoading && (
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress />
                    </Box>
                )}
                {badgingStatusResults && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom align="center">
                            Results
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<ContentCopyIcon />}
                                        onClick={() => handleCopy(ASIN_STATUS.BADGED)}
                                    >
                                        Badged ASINS
                                    </Button>
                                </Box>
                                <List sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    {badgingStatusResults.true
                                        .map((asin, index) => ( 
                                            <ListItem key={`${asin}-${index}`} sx={{
                                                justifyContent: 'center',
                                                width: 'auto'
                                            }}>
                                                <ListItemText
                                                    primary={asin}
                                                    sx={{
                                                        color: 'green',
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<ContentCopyIcon />}
                                        onClick={() => handleCopy(ASIN_STATUS.UNBADGED)}
                                    >
                                        Unbadged ASINS
                                    </Button>
                                </Box>
                                <List sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    {badgingStatusResults.false
                                        .map((asin, index) => ( 
                                            <ListItem key={`${asin}-${index}`} sx={{
                                                justifyContent: 'center',
                                                width: 'auto'
                                            }}>
                                                <ListItemText
                                                    primary={asin}
                                                    sx={{
                                                        color: 'red',
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={()=> setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </Container>
    );
};

export default CheckBadgingStatus;
