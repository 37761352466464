import React, { useState, ChangeEvent, FormEvent } from "react";
import {
    Button,
    Typography,
    Box,
    Paper,
    Container,
    Grid,
    Snackbar
} from "@mui/material";
import { LinearProgressWithLabel } from "src/components/loader/LinearProgressWithLabel"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from "react-redux";
import {
    ASINChunkProcessingResult,
    MAX_FILE_SIZE,
    AddAsinsForBadgingApiCallResult,
    CONCURRENCY_LIMIT_FOR_ASIN_STORAGE_API_CALL,
    CSV_FILE_SIZE_LIMIT_EXCEED_ERROR,
    API_CALL_SUCCESS_MESSAGE,
    ASIN_UPLOAD_CHUNK_SIZE,
    BadgingRequestBody
} from "src/constants/OOCFBADiscoverabilityConstants";
import { apiPostRequest } from "src/utils/ApiPostRequest";
import { CombineReducerState } from "src/combineReducers";
import { routes } from "src/data/Routes";


const BadgeAsins: React.FC = () => {
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [unprocessedAsins, setUnprocessedAsins] = useState<string[]>([]);
    const [invalidAsins, setInvalidAsins] = useState<string[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [totalAsins, setTotalAsins] = useState<string[]>([]);
    const [message, setMessage] = useState('');
    const [isResultPaneOpen, setIsResultPaneOpen] = useState(false);
    const [chunkProcessingProgress, setChunkProcessingProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = useSelector((state: CombineReducerState) => state.navbar.baseUrl);
    const apiRequestPath = routes.isp.OOCFBADiscoverability.badgeAsins;


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            if (files[0].size > MAX_FILE_SIZE) {
                event.target.value = '';
                setCsvFile(null);
                setMessage(CSV_FILE_SIZE_LIMIT_EXCEED_ERROR);
                setOpenSnackbar(true);
                return;
            }
            setCsvFile(files[0]);
        }
    };

    const createBadgingRequestBody = (inputList: string[]): BadgingRequestBody => {
        return {
            asins: inputList
        }
    };

    const makeTheApiCall = async (asins: string[]) => {
        const chunks: string[][] = [];

        for (let i = 0; i < asins.length; i += ASIN_UPLOAD_CHUNK_SIZE) {
            const chunk = asins.slice(i, i + ASIN_UPLOAD_CHUNK_SIZE);
            chunks.push(chunk);
        }

        const totalChunks = chunks.length;
        let processedChunks = 0;
        
        setChunkProcessingProgress(0);
        setIsLoading(true);

        for (let i = 0; i < chunks.length; i += CONCURRENCY_LIMIT_FOR_ASIN_STORAGE_API_CALL) {
            const chunkGroup = chunks.slice(i, i + CONCURRENCY_LIMIT_FOR_ASIN_STORAGE_API_CALL);
            const promises: Promise<ASINChunkProcessingResult>[] = chunkGroup.map((chunk, index) => {

                return apiPostRequest(baseUrl + apiRequestPath,
                    createBadgingRequestBody(chunk)
                ).then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed for chunk ${i + index + 1}`);
                    }
                    return response.json();
                }).then((data: AddAsinsForBadgingApiCallResult) => {
                    return { success: true, data };
                }).catch(error => {
                    setUnprocessedAsins(prevAsins => [...prevAsins, ...chunk]);
                    return { success: false, error: error.message };
                }).finally(() => {
                    processedChunks++;
                    setChunkProcessingProgress(Math.round((processedChunks / totalChunks) * 100));
                });

            });

            const results: ASINChunkProcessingResult[] = await Promise.all(promises);
        
            results.forEach(result => {
                if(result.success){
                    if(result.data != null){
                        setInvalidAsins(prevAsins => [...prevAsins, ...result.data!.invalidAsins]);
                        setUnprocessedAsins(prevAsins => [...prevAsins, ...result.data!.unprocessedAsins]);
                    }
                }
            })   
        }

        setMessage(API_CALL_SUCCESS_MESSAGE);
        setOpenSnackbar(true);
        setIsResultPaneOpen(true);
        setIsLoading(false);
    };

    const processCSVContent = (csvContent: string) => {
        const lines = csvContent.split(/\r\n|\n|\r/);

        const parsedAsins: string[] = lines
            .flatMap(line => {
                return line.split(',')
                    .map(value => value.trim())
                    .filter(value => value && value.length > 0);
            });

        setTotalAsins(parsedAsins);
        makeTheApiCall(parsedAsins);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setInvalidAsins([]);
        setUnprocessedAsins([]);
        setTotalAsins([]);
        setIsResultPaneOpen(false);
        if (csvFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const content = e.target!.result as string;
                    processCSVContent(content);
                } catch (error: any) {
                    setMessage(error.message);
                }
            };
            reader.readAsText(csvFile);
        }
    };

      return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Discoverability for OOC-FBA Products
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Paper elevation={2} sx={{ p: 3, bgcolor: 'grey.100' }}>
                            <Typography variant="h6" gutterBottom align="center">
                                Instructions
                            </Typography>
                            <Typography variant="body1" paragraph align="center">
                                We use a special badge to distinguish OOC-FBA Products at Amazon.in,
                                This badge is known as the International Brands Provenance Certificate.
                            </Typography>
                            <Typography variant="body1" paragraph align="center">
                                In order to attach the badge to a specific product you must provide its ASIN in a csv file and upload it here
                            </Typography>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 2,
                                    mt: 3
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<UploadFileIcon />}
                                    size="large"
                                >
                                    Upload CSV File
                                    <input
                                        type="file"
                                        hidden
                                        accept=".csv"
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                {csvFile && (
                                    <Typography variant="body2">
                                        Selected file: {csvFile.name}
                                    </Typography>
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!csvFile || isLoading}
                                    size="large"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                
                <Box sx={{ mt: 4 }}>
                    {isLoading && 
                        <LinearProgressWithLabel value={chunkProcessingProgress} sx={{ mb: 2 }}/> 
                    }
                    {isResultPaneOpen && (
                        <Paper elevation={2} sx={{ p: 3 }}>
                            <Typography variant="h4" gutterBottom>
                                Submission Result
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Total Values Found in File: {totalAsins.length}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Successfully Registered {totalAsins.length - (unprocessedAsins.length + invalidAsins.length)} Valid ASINs
                            </Typography>
                            <Box sx={{ 
                            display: 'flex', 
                            flexDirection: invalidAsins.length > 0 && unprocessedAsins.length > 0 ? 'row' : 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 3
                        }}>
                            {invalidAsins.length > 0 && (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="body1" gutterBottom>
                                        Invalid ASINs: {invalidAsins.length}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<ContentCopyIcon />}
                                        color="error"
                                        onClick={() => {
                                            navigator.clipboard.writeText(invalidAsins.join(','));
                                            setMessage("Invalid ASINs copied to clipboard");
                                            setOpenSnackbar(true);
                                        }}
                                    >
                                        Invalid ASINs
                                    </Button>
                                </Box>
                            )}
                            {unprocessedAsins.length > 0 && (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="body1" gutterBottom>
                                        Unprocessed ASINs: {unprocessedAsins.length}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<ContentCopyIcon />}
                                        color="error"
                                        onClick={() => {
                                            navigator.clipboard.writeText(unprocessedAsins.join(','));
                                            setMessage("Unprocessed ASINs copied to clipboard");
                                            setOpenSnackbar(true);
                                        }}
                                    >
                                        Unprocessed ASINs
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        </Paper>
                    )}
                </Box>
            </Paper>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setOpenSnackbar(false)}
                message={message}
            />
        </Container>
    );
}

export default BadgeAsins;
