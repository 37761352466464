// Generate a Random String
export const getRandomString = () => {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems = randomItems.map(dec => (dec / 100) % 100)
    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

export function isLegalAsin(asin: string): boolean {
    if (asin.length !== 10) return false;

    const firstChar = asin.charAt(0);
    const lastChar = asin.charAt(9);

    if (/\d/.test(firstChar)) {  
        return /^\d{9}$/.test(asin.substring(0, 9)) && 
               (/\d/.test(lastChar) || lastChar === 'X'); 
    } else {
        return /^[A-Z0-9]{10}$/.test(asin);
    }
}
