import { routes } from "./Routes";

export const menuItems: Record<string, Array<MenuItem>> = {
    "Seller Registration": [
  
        {

            "title": "Global Seller Registration JITA Metrics",

            "group": "Utilities",

            "to": routes.sellerRegistration.globalRegistrationMetrics,

            "disabled": false,

            "children": []

        }

    ],
    "Seller Flags": [
        {
            "title": "Update PARIS Flags",
            "group": "Seller Flags",
            "to": routes.sellerFlags.performFlagUpdates,
            "disabled": false,
            "children":[]
        },
        {
            "title": "Get PARIS Flag Details",
            "group": "Seller Flags",
            "to": routes.sellerFlags.getFlagDetails,
            "disabled": false,
            "children":[]
        },
    ],
    "ISP": [
        {
            "title": "Reports",
            "group": "ISP",
            "to": "",
            "disabled": false,
            "children":[
                {
                    "title": "MTR Reports",
                    "group": "ISP Reports",
                    "to": routes.isp.reports.mtr,
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "Settlement Reports",
                    "group": "ISP Reports",
                    "to": routes.isp.reports.settlement,
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "Generate Inventory Shipment Reports",
                    "group": "ISP Reports",
                    "to": routes.isp.reports.generateInventoryShipment,
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "Inventory Shipment Reports",
                    "group": "ISP Reports",
                    "to": routes.isp.reports.inventoryShipment,
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "GST Aggregated Reports",
                    "group": "ISP Reports",
                    "to": routes.isp.reports.gstAggregatedReport,
                    "disabled": false,
                    "children":[]
                }
        
            ]
        },
        {
            "title": "OOC-FBA Discoverability",
            "group": "ISP",
            "to": "",
            "disabled": false,
            "children":[
                {
                    "title": "Enter ASINs for Badging",
                    "group": "OOC-FBA Discoverability",
                    "to": routes.isp.OOCFBADiscoverability.badgeAsins,
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "Check Badging status",
                    "group": "OOC-FBA Discoverability",
                    "to": routes.isp.OOCFBADiscoverability.checkBadgingStatus,
                    "disabled": false,
                    "children":[]
                }
            ]
        },
        {
            "title": "GST Allowlist",
            "group": "ISP",
            "to": "",
            "disabled": false,
            "children":[
                {
                    "title": "Get GST Allowlist",
                    "group": "GST Allowlist",
                    "to": routes.isp.gst.getGSTAllowlist + "get",
                    "disabled": false,
                    "children":[]
                },
                {
                    "title": "Update GST Allowlist",
                    "group": "GST Allowlist",
                    "to": routes.isp.gst.updateGSTAllowlist + "update",
                    "disabled": false,
                    "children":[]
                },
            ]
        },
        {
            "title": "OOC FBA Seller Onboarding",
            "group": "ISP",
            "to": routes.isp.oocFBASellerOnboard,
            "disabled": false,
            "children":[]
        },
        {
            "title": "ISP Onboarding",
            "group": "ISP",
            "to": routes.isp.ispOnboarding,
            "disabled": false,
            "children":[]
        }
    ],
    "Utilities": [
        {
            "title": "Encrypt/Decrypt AmazonIds",
            "group": "Utilities",
            "to": routes.utilities.encryptDecryptId,
            "disabled": false,
            "children":[]
        },
        {
            "title": "Crescent Subscriber",
            "group": "Utilities",
            "to": routes.utilities.crescentSubscriber,
            "disabled": false,
            "children":[]
        },
        {
            "title": "Apply Add-on Subscription",
            "group": "Utilities",
            "to": routes.utilities.applyAddOnSubscription,
            "disabled": false,
            "children":[]
        },
        {
            "title": "Remove Add-on Subscription",
            "group": "Utilities",
            "to": routes.utilities.removeAddOnSubscription,
            "disabled": false,
            "children":[]
        }
    ],
    "Cross Launch Metrics": [
        {
            "title": "Scorpion Metrics",
            "group": "Cross Launch Metrics",
            "to": routes.crossLaunchMetrics.scorpionMetrics,
            "disabled": false,
            "children":[]
        },
    ]
}

export interface MenuItem {
    title: string,
    group: string,
    to: string,
    disabled: boolean,
    children:Array<SubMenuItem> 
}

export interface SubMenuItem {
    title: string,
    group: string,
    to: string,
    disabled: boolean,
    children:Array<Object>
}