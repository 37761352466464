import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Home from "src/views/Home";
import Navbar from "./navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Sidebar from "./sidebar/Sidebar";
import { CombineReducerState } from "src/combineReducers";
import { menuItems } from "src/data/MenuItems";
import ListOrders from "src/views/seller-orders/list-orders/ListOrders";
import { routes } from "src/data/Routes";
import { authorizeUser } from "src/auth/Authorization";
import { cookie } from "src/utils/Cookie";
import { Cookie } from "src/constants/Cookie";
import NotFound from "src/views/error/NotFound";
import L2Rejects from "src/views/payments/l2-rejects/L2Rejects";
import ListOrdersDateRange from "src/views/seller-orders/date-range/ListOrdersDateRange";
import CommercialInvoiceStatusForAShipmentId from "src/views/invoices/CommercialInvoiceStatusForAShipmentId";
import CommercialInvoiceStatusDateRange from "src/views/invoices/CommercialInvoiceStatusDateRange";
import SellerRegistrationMetrics from "src/views/seller-registration/metrics/SellerRegistrationMetrics";
import SellerRegistrationMetricsOfADay from "src/views/seller-registration/metrics/day/SellerRegistrationMetricsOfADay";
import { isDarkThemeAction } from "./navbar/NavbarActions";
import AWBInvoiceFailure from "src/views/invoices/AWBInvoiceFailure";
import EncryptDecrypt from "src/views/utilities/enycrypt-decrypt/EncryptDecrypt";
import MTRReports from "src/views/isp-reports/mtr/MTRReports";
import PerformAnkanaAllowList from "src/views/utilities/ankana-tag/PerformAnkanaAllowList";
import OPGSPPaymentFailure from "src/views/payments/opgsp-payment-failure/OPGSPPaymentFailure";
import GetSellerFlagDetails from "src/views/seller-flags/details/GetSellerFlagDetails";
import UpdateSellerFlagDetails from "src/views/seller-flags/details/UpdateSellerFlagDetails";
import ApplyAddOnSubscription from "src/views/utilities/subscription-tier/ApplyAddOnSubscription";
import RemoveAddOnSubscription from "src/views/utilities/subscription-tier/RemoveAddOnSubscription";
import CrescentSubscriber from "src/views/utilities/crescent-subscriber/CrescentSubscriber";
import GenerateInventoryShipmentReports from "src/views/isp-reports/generate-inventory/GenerateInventoryShipmentReports";
import SettlementReports from "src/views/isp-reports/settlement/SettlementReports";
import InventoryShipmentReports from "src/views/isp-reports/inventory-shipment/InventoryShipmentReports";
import GetAllowedGSTINList from "src/views/isp/allowed-gstin-list/GetAllowedGSTINList";
import UpdateAllowedGSTINList from "src/views/isp/allowed-gstin-list/UpdateAllowedGSTINList";
import OOCFBASellerOnboard from "src/views/isp/seller-onboarding/OOCFBASellerOnboard";
import ScorpionMetrics from "src/views/cross-launch-metrics-dashboard/ScorpionMetrics";
import ISPOnboarding from "src/views/isp/isp-onboarding/ISPOnboarding";
import GlobalSellerRegistrationJitaMetrics from "src/views/seller-registration/metrics/global-seller-registration-metrics/GlobalSellerRegistrationJitaMetrics";
import { GSTAggregatedReport } from "src/views/isp-reports/GSTAggregatedReport";
import BadgeAsins from "src/views/isp/oocfba-discoverability/BadgeAsins";
import CheckBadgingStatus from "src/views/isp/oocfba-discoverability/CheckBadgingStatus";
export const BASE_TITLE = "Voldemort 2.0";
export const DELIMITER = " - ";

export interface PageProps {
  title: string;
}

const App = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(cookie.get(Cookie.TOKEN));
  const isDarkTheme = useSelector(
    (state: CombineReducerState) => state.navbar.isDarkTheme
  );
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkTheme ? "dark" : "light",
        },
      }),
    [isDarkTheme]
  );

  // Check auth!
  useEffect(() => {
    if (!token) {
      const getToken = async () => setToken(await authorizeUser());
      getToken();
      removeQueryParams();
    } else {
      console.log("Token present!");
    }

    const savedTheme = localStorage.getItem(Cookie.THEME);
    if (savedTheme) dispatch(isDarkThemeAction(savedTheme === "dark"));
  }, []);

  const removeQueryParams = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  if (!token) {
    return (
      <Typography variant="subtitle1">Redirecting to midway...</Typography>
    );
  }
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navbar />
          <Sidebar menuItems={menuItems} />
          <CssBaseline />
          <Container maxWidth="xl" sx={{ my: 1 }}>
            <Routes>
              <Route path={routes.index} element={<Home title="Home" />} />
              <Route
                path={routes.payments.l2Rejects}
                element={<L2Rejects title="L2 rejects" />}
              />
              <Route
                path={routes.invoices.CommercialInvoiceStatus}
                element={
                  <CommercialInvoiceStatusForAShipmentId title="Commercial Invoice Status" />
                }
              />
              <Route
                path={routes.utilities.encryptDecryptId}
                element={<EncryptDecrypt title="Encryption decryption" />}
              />
              <Route
                path={routes.utilities.ankanaTag}
                element={<PerformAnkanaAllowList title="Ankana Tag" />}
              />
              <Route
                  path={routes.utilities.crescentSubscriber}
                  element={<CrescentSubscriber title="Crescent Subscriber" />}
              />
              <Route
                path={routes.invoices.CommercialInvoiceStatusInDateRange}
                element={
                  <CommercialInvoiceStatusDateRange title="Commercial Invoice Status in date range" />
                }
              />
              <Route
                path={routes.sellerRegistration.metrics}
                element={
                  <SellerRegistrationMetrics title="Seller Registration Metrics" />
                }
              />
              <Route
                path={routes.sellerRegistration.metricsOfDay}
                element={
                  <SellerRegistrationMetricsOfADay title="Seller Registration Metrics of a day" />
                }
              />
              <Route
                path={routes.invoices.awbInvoiceFailure}
                element={<AWBInvoiceFailure title="AWB Invoice Failure" />}
              />
              <Route
                path={routes.isp.reports.mtr}
                element={<MTRReports title="MTR Reports" />}
              />
              <Route
                path={routes.isp.reports.generateInventoryShipment}
                element={<GenerateInventoryShipmentReports title="Generate Inventory Shipment Reports" />}
              />
              <Route
                path={routes.isp.reports.settlement}
                element={<SettlementReports title="Settlement Reports" />}
              />
              <Route
                path={routes.isp.reports.inventoryShipment}
                element={<InventoryShipmentReports title="Inventory Shipment Reports" />}
              />
              <Route
                path={routes.isp.reports.gstAggregatedReport}
                element={<GSTAggregatedReport title="GST Aggregated Reports" />}
              />
              <Route
                path={routes.isp.gst.getGSTAllowlist + "get"}
                element={<GetAllowedGSTINList title={"Get GST Allowlist"} />}
              />
              <Route
                path={routes.isp.gst.updateGSTAllowlist + "update"}
                element={<UpdateAllowedGSTINList title={"Update GST Allowlist"} />}
              />
               <Route
                path={routes.isp.oocFBASellerOnboard}
                element={<OOCFBASellerOnboard title={"OOC FBA Seller Onboarding"} />}
              />
              <Route
                  path={routes.isp.ispOnboarding}
                  element={<ISPOnboarding title={"ISP Onboarding"} />}
              />
              <Route
                  path={routes.isp.OOCFBADiscoverability.badgeAsins}
                  element={<BadgeAsins  />}
              />
              <Route
                  path={routes.isp.OOCFBADiscoverability.checkBadgingStatus}
                  element={<CheckBadgingStatus />}
              />
              <Route
                path={routes.payments.opgspPaymentFailure}
                element={<OPGSPPaymentFailure title="OPGSP Payment Failure" />}
              />
              <Route
                path={routes.sellerFlags.getFlagDetails}
                element={
                  <GetSellerFlagDetails title="Get Seller Flag Details" />
                }
              />
              <Route
                path={routes.sellerFlags.performFlagUpdates}
                element={
                  <UpdateSellerFlagDetails title="Update Seller Flag Details" />
                }
              />
              <Route
                path={routes.utilities.applyAddOnSubscription}
                element={
                  <ApplyAddOnSubscription title="Apply Add-on Subscription" />
                }
              />
              <Route
                path={routes.utilities.removeAddOnSubscription}
                element={
                  <RemoveAddOnSubscription title="Remove Add-on Subscription" />
                }
              />
              <Route
	              path={routes.sellerRegistration.globalRegistrationMetrics}
                element={
                  <GlobalSellerRegistrationJitaMetrics title="JITA IN Seller Metrics" />
                } 
	            />
              <Route
                path={routes.crossLaunchMetrics.scorpionMetrics}
                element={
                  <ScorpionMetrics title="Scorpion Metrics" />
                } 
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;