import {cookie} from "src/utils/Cookie";
import {Cookie} from "src/constants/Cookie";

export const apiPostRequest = async (url: string, body: Record<string ,any> = {}): Promise<Response> => {
    return await fetch(url , {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": cookie.get(Cookie.TOKEN)
        },
        body: JSON.stringify(body)
    });
}
